import {
  Card,
  createStyles,
  TableContainer,
  makeStyles,
  Theme,
  Box,
  Button,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { Rnd } from "react-rnd";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  ListContextProvider,
  Record,
  useList,
} from "react-admin";
import { Link } from "react-router-dom";

import { SubjectField } from "../../components/SubjectField";
import { Segment } from "../types";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { TableButton } from "./TableButton";
import { Delivery } from "../../shared/types";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { useStore } from "../store";
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { DownloadNoteFileButton } from "../../components/DownloadNoteFileButton";

interface Props {
  onAddClick: (segment: Segment) => void;
  onSelectDelivery: (delivery: Delivery) => void;
}
const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    root: {
      zIndex: 10000,
    },
  })
);
const useStylesTable = makeStyles((theme) => ({
  root: { maxHeight: "400px", overflow: "auto" },
}));
const useStylesDatagrid = makeStyles(() => ({
  rowCell: {
    whiteSpace: "normal",
  },
}));
export const MarkerPopup = React.forwardRef<Rnd, Props>((props, ref) => {
  const classes = useStyles(props);
  const classesTable = useStylesTable(props);
  const classesDatagrid = useStylesDatagrid();
  const {
    subjectByCoordsMap,
    clusters,
    activeClusterKey,
    addedSegments,
    selectedRoute,
  } = useStore();
  const segments = useMemo(
    () => (activeClusterKey ? clusters[activeClusterKey] : []),
    [activeClusterKey, clusters]
  );
  const listContext = useList({
    data: segments,
    ids: segments.map((segment) => segment.id),
    loading: false,
    loaded: true,
  });

  return (
    <Rnd
      ref={ref}
      className={classes.root}
      enableResizing={false}
      maxWidth={"710px"}
      default={{ x: 0, y: 0, width: "auto", height: "auto" }}
    >
      {activeClusterKey && segments.length > 0 && (
        <Card>
          <Box padding={2}>
            <Link
              to={`/subjects/${subjectByCoordsMap[activeClusterKey].id}`}
              target="_blank">
              {`${subjectByCoordsMap[activeClusterKey].id}:${subjectByCoordsMap[activeClusterKey].business_name}, ${subjectByCoordsMap[activeClusterKey].locality} ${subjectByCoordsMap[activeClusterKey].province}`}
            </Link>
          </Box>
          <TableContainer classes={classesTable}>
            <ListContextProvider value={listContext}>
              <Datagrid hover={false} classes={classesDatagrid}>
                <FunctionField
                  label=""
                  render={(tableRecord: Record | undefined) => {
                    if (
                      tableRecord &&
                      (!tableRecord.route_id ||
                        (selectedRoute &&
                          tableRecord.route_id === selectedRoute.id))
                    ) {
                      const added = addedSegments.find(
                        (segment) => segment.id === tableRecord?.id
                      );

                      return (
                        <TableButton
                          title={added ? "Elimina" : "Aggiungi"}
                          onClick={() => {
                            props.onAddClick(tableRecord as Segment);
                          }}
                        >
                          {added ? <DeleteIcon /> : <AddIcon />}
                        </TableButton>
                      );
                    }
                    return null;
                  }}
                />
                <FunctionField
                  label=""
                  render={(tableRecord: Record | undefined) => {
                    const record = tableRecord ? tableRecord.delivery : null;
                    if (record) {
                      return (
                        <TableButton
                          title={"Seleziona"}
                          onClick={() => {
                            props.onSelectDelivery(record);
                          }}
                        >
                          <PlaylistAddIcon />
                        </TableButton>
                      );
                    }
                    return null;
                  }}
                />
                <FunctionField
                  label="Bolle"
                  source="note_set"
                  render={(tableRecord: Record | undefined) => {
                    if (tableRecord && tableRecord.note_set) {
                      return tableRecord.note_set.map((note: any) => (
                        <DownloadNoteFileButton
                          key={note.id}
                          title={note.file}
                          noteId={note.id}
                          final={note.final}
                        />
                      ));
                    }
                    return null;
                  }}
                />
                <FunctionField
                  source="delivery"
                  label="ID"
                  render={(record: any) => (
                    <Button
                      color="primary"
                      component={Link}
                      to={"/deliveries/" + record.delivery.id}
                      target="_blank"
                    >
                      <>
                        {record
                          ? `${record.delivery.id}/${record.order_on_delivery}`
                          : null}
                      </>
                    </Button>
                  )}
                />
                <SubjectField
                  source="starting_point"
                  label="Punto di partenza"
                />

                <FunctionField
                  source="delivery"
                  label="Cliente"
                  render={(record: Record | undefined) => (
                    <>
                      {record
                        ? `${record.delivery.client.id}: ${record.delivery.client.business_name}`
                        : null}
                    </>
                  )}
                />
                <FunctionField
                  source="delivery"
                  label="Colli"
                  render={(record: Record | undefined) => (
                    <>{record ? record.delivery.packages_number : null}</>
                  )}
                />

                <FunctionField
                  source="delivery"
                  label="Parziale"
                  render={(record: Record | undefined) => (
                    <>{record && record.delivery.partial ? (<>
                      <CheckBox /></>) : (<CheckBoxOutlineBlank />)}</>
                  )}
                />

                <FunctionField
                  source="delivery"
                  label="Peso"
                  render={(record: Record | undefined) => (
                    <>{record ? record.delivery.weight : null}</>
                  )}
                />

                <FunctionField
                  source="delivery"
                  label="Giro"
                  render={(record: Record | undefined) => (
                    <>{record ? record.route_id : null}</>
                  )}
                />

                <DeliveryTypeField source="delivery_type" label={"Tipo"} />

              </Datagrid>
            </ListContextProvider>
          </TableContainer>
        </Card>
      )}
    </Rnd>
  );
});
