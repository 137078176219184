import { AutocompleteArrayInput, AutocompleteInput, Datagrid, DateInput, ListContextProvider, ListProps, NumberField, ReferenceArrayInput, ReferenceInput, TextField, useList, useRecordContext } from "react-admin";
import { CustomList } from "../../components/CustomList";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { getDefaultListProps, initField } from "../../shared/utils";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { useOptions } from "../../shared/hooks/useOptions";
import { SubjectField } from "../../components/SubjectField";
import { Record } from "react-admin";
import { TableContainer } from "@material-ui/core";
import { AdditionalActions } from "./AdditionalActions";


export const DeliveryMergerProvince = () => {
  const { data: options } = useOptions("delivery_mergers", "GET");

  const mainRecord = useRecordContext();
  const listContext = useList({
    data: mainRecord!.provinces,
    ids: mainRecord!.provinces.map((item: Record) => item.id),
    loading: false,
    loaded: true,
  });

  return options ? (

    <ListContextProvider value={listContext}>
      <TableContainer>
        <Datagrid>
          <TextField {...initField("province", options,)} label={"Province"} />,
          <NumberField {...initField("packages_number", options)} label={"Colli"} />,
          <NumberField {...initField("weight", options,)} label={"Peso"} />,
          <NumberField {...initField("delivery_count", options)} label={"Missioni totali"} />
          <NumberField {...initField("taxable_sum", options)} label={"Imponibile"} />
        </Datagrid>
      </TableContainer>
    </ListContextProvider>

  ) : null;
};

export const DeliveryMergerList = (props: ListProps) => {
  const { data: options } = useOptionsResource("GET");

  const filters = options
    ? [
      <DateInput
        alwaysOn
        {...initField("delivery_date_after", options)}
        label="Data missione dal"
      />,
      <DateInput
        alwaysOn
        {...initField("delivery_date_before", options)}
        label="Data missione fino al"
      />,
      <DateInput {...initField("document_date_exact", options)}
        label="Data doc." />,
      <ReferenceInputSubject
        {...initField("client", options)}
        filter={{ type: "client" }}
        className="filter-field-small"
      />,
      <DateInput
        {...initField("document_date_after", options)}
        label="Data documento dal"
      />,
      <DateInput
        {...initField("document_date_before", options)}
        label="Data documento fino al"
      />,
      <DateInput
        {...initField("initial_delivery_date_after", options)}
        label="Data ins. dal"
      />,
      <ReferenceInput
        source="province"
        label="Provincia"
        reference="autocomplete/province"
        className="filter-field-small"
      >
        <AutocompleteInput optionText="label" />
      </ReferenceInput>,
      <ReferenceArrayInput
        source="region"
        label="Regioni"
        reference="autocomplete/region"
        className="filter-field-small"
      >
        <AutocompleteArrayInput optionText="label" />
      </ReferenceArrayInput>,
      /*  
    
    <DateTimeInput
      {...initField("delivery_date_after", options)}
      label="Data missione dal"
    />,
    <DateTimeInput
      {...initField("delivery_date_before", options)}
      label="Data missione fino al"
    />,
    <DateInput
      {...initField("document_date_after", options)}
      label="Data documento dal"
    />,
    <DateInput
      {...initField("document_date_before", options)}
      label="Data documento fino al"
    />,
    <TextInput
      {...initField("province", options)}
      label="Provincia"
    />,
  */
    ]
    : [];



  return options ? (
    <CustomList
      sort={{ field: "client", order: "DESC" }}
      filters={filters}
      shouldExport={true}
      filterDefaultValues={{
      }}
      {...getDefaultListProps(props)}
      bulkActionButtons={false}
      additionalActions={<AdditionalActions />}
      pagination={false}
    >
      <Datagrid
        expand={DeliveryMergerProvince}>

        <SubjectField
          {...initField("client", options)}
          label={"Cliente"}
        />
        <NumberField
          {...initField("packages_number", options)}
          label={"Colli "}
        />
        <NumberField
          {...initField("weight", options)}
          label={"Peso"}
        />
        <NumberField
          {...initField("delivery_count", options)}
          label={"Missioni totali"}
        />
        <NumberField
          {...initField("taxable_sum", options)}
          label={"Imponibile"}
        />
      </Datagrid>
    </CustomList>
  ) : null;
};
