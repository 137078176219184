import {
  Datagrid,
  TextField,
  ListProps,
  NumberField,
  BulkDeleteButton,
  FunctionField,
  Record,
  Button,
} from "react-admin";
import { CustomList } from "../../components/CustomList";
import { getDefaultListProps, getFileNameFromContentDisposition } from "../../shared/utils";

import { XMLImportButton } from "./XMLImportButton";
import { client, clientNoJson } from "../../dataProvider";
import { AxiosResponse } from "axios";
export const XMLFileUploadList = (props: ListProps) => {

  const handleSubmit = async (filename: string) => {
    // download file from this api download_xml_import

    let res: AxiosResponse<any, any>
    try {
      res = await clientNoJson(`/api/xml_invoices_imports/download_xml_import/`, {
        method: "POST",
        data: {
          filename: filename,
        },
        responseType: "arraybuffer",
      });
    } catch (err) {
      //notify("ra.message.error", "error");
      window.alert("Errore")
      return;
    }
    const data = res.data; // or res.blob() if using blob responses

    const url = window.URL.createObjectURL(
      new Blob([data], {
        type: res.headers["content-type"],
      })
    );
    const actualFileName = getFileNameFromContentDisposition(
      res.headers["content-disposition"]
    );

    // uses the download attribute on a temporary anchor to trigger the browser
    // download behavior. if you need wider compatibility, you can replace this
    // part with a library such as filesaver.js
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", actualFileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);

  };

  return (
    <CustomList
      {...getDefaultListProps(props)}
      pagination={false}
      perPage={Infinity}
      additionalActions={<XMLImportButton
      />}
      bulkActionButtons={<BulkDeleteButton />}
    >
      <Datagrid>
        <TextField source="name" label="Nome file" />
        <NumberField source="size" label="Dimensione" />
        <FunctionField render={
          function (record?: Record | undefined, source?: string) {
            return record ? (<Button onClick={() => {
              handleSubmit(record.name)
            }}><a >Scarica file</a></Button>
            ) : null
          }} />
      </Datagrid>
    </CustomList >
  );
};
