import {
  ArrayField,
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DatagridProps,
  DateField,
  DateInput,
  FunctionField,
  Identifier,
  ListContextProvider,
  NumberField,
  Record,
  RecordContextProvider,
  ReferenceField,
  SelectField,
  SingleFieldList,
  TextField,
  TextInput,
  useList,
  useRecordContext,
  useRefresh,
  useUpdate,
} from "react-admin";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, Grid, IconButton, makeStyles, Table, TableCell, TableContainer, TableRow, Typography, useTheme } from "@material-ui/core";
import { useOptionsResource } from "../../shared/hooks/useOptionsResource";
import { useOptions } from "../../shared/hooks/useOptions";
import { IdentifierField } from "../../components/IdentifierField";
import { initField } from "../../shared/utils";
import { RouteField } from "../../components/RouteField";
import { ReferenceInputSubject } from "../../components/reference-inputs/ReferenceInputSubject";
import { usePanzoom } from "../../shared/hooks/usePanzoom";
import { useSize } from "../../shared/hooks/useSize";
import { getNoteImage, saveNoteToFile } from "../../Distribution/Note/utils";
import { DeliveryField } from "../../components/DeliveryField";
import { SubjectField } from "../../components/SubjectField";
import NavigateNext from "@material-ui/icons/SkipNext";
import NavigatePrev from "@material-ui/icons/SkipPrevious";
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBox from '@material-ui/icons/CheckBox';
import GetAppIcon from "@material-ui/icons/GetApp";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Close from "@material-ui/icons/Close";
import { client } from "../../dataProvider";
import { AddDeliveryStatusButton } from "../../Distribution/Delivery/AddDeliveryStatusButton";
import { AddDeliveryStatusButtonWarehouse } from "../../Distribution/Delivery/AddDeliveryStatusButtonWarehouse";
import { LittleSubjectField } from "../../components/LittleSubjectField";
import { LittleDeliveryField } from "../../components/LittleDeliveryField";
import { DeliveryTypeField } from "../../components/DeliveryTypeField";
import { EditableDatagrid } from "../../components/EditableDatagrid";
import { EditableCheckboxField } from "../../components/EditableCheckboxField";
import { LoadedEditableCheckboxField } from "./LoadedEditableCheckboxField";
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { set } from "lodash";


interface AsideProps {
  activeDelivery: Record | undefined;
  closeAside: () => void;
}
interface NoteGalleryProps {
  activeNoteImage: string;
  fileName: string;
  rotation: number;
}
const NoteGallery = ({ activeNoteImage, fileName, rotation }: NoteGalleryProps) => {
  const handlePanzoom = usePanzoom();

  return (
    <>
      <div ref={handlePanzoom} style={{ height: "100%" }}>
        <img
          alt={fileName}
          style={{ width: "auto", height: "100%", transform: `rotate(${rotation}deg)` }}
          src={activeNoteImage}
        />
      </div>
    </>
  );
};
const Aside = (props: AsideProps) => {
  const { activeDelivery, closeAside } = props;
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const size = useSize(document.getElementById("layout-main-content"));
  const sizeDeliveryDetails = useSize(ref.current);
  const heightNoteBox =
    (size ? size.height : 0) -
    (sizeDeliveryDetails ? sizeDeliveryDetails.height : 0);

  const [rotation, setRotation] = useState(0);

  const [indexActiveNote, setIndexActiveNote] = useState<number>(0);
  const [activeNoteImage, setActiveNoteImage] = useState<string>();
  const goNext = () => {
    const nextIndex = indexActiveNote + 1;
    if (
      activeDelivery &&
      activeDelivery.delivery_notes &&
      nextIndex < activeDelivery.delivery_notes.length
    )
      setIndexActiveNote(nextIndex);
  };
  const goPrev = () => {
    const prevIndex = indexActiveNote - 1;
    if (prevIndex >= 0) setIndexActiveNote(prevIndex);
  };
  const canGoPrev = useMemo(() => indexActiveNote - 1 >= 0, [indexActiveNote]);
  const canGoNext = useMemo(
    () =>
      activeDelivery &&
      activeDelivery.delivery_notes &&
      indexActiveNote + 1 < activeDelivery.delivery_notes.length,
    [activeDelivery, indexActiveNote]
  );

  const getActiveNoteImage = useCallback(async () => {
    if (
      activeDelivery &&
      activeDelivery.delivery_notes &&
      activeDelivery.delivery_notes.length > 0
    ) {
      const image = await getNoteImage(
        activeDelivery.delivery_notes[indexActiveNote].id
      );
      return image;
    }
  }, [activeDelivery, indexActiveNote]);

  useEffect(() => {
    (async function fetchImage() {
      const image = await getActiveNoteImage();
      setActiveNoteImage(image);
    })();
  }, [getActiveNoteImage]);

  return activeDelivery ? (
    <RecordContextProvider value={activeDelivery}>
      <Box height={size?.height} position="sticky" top="0" bgcolor={"white"}>
        <div ref={ref}>
          <TableContainer>
            <Datagrid
              data={{ [activeDelivery.id]: activeDelivery }}
              ids={[activeDelivery.id]}
            >
              <LittleDeliveryField deliverySource="delivery_id" label="Missione" />
              <TextField source="document_number" label="Num. documento" />
              <DeliveryTypeField source="delivery_type" label={"Tipo"} />
              <LittleSubjectField source="arrival_point" label="Destinatario" />
              <TextField source="document_date" label="Data documento" />
            </Datagrid>
          </TableContainer>
        </div>
        {activeDelivery.notes && (
          <Typography
            style={{ border: "2px solid red", padding: "3px" }}
            color="error" variant={"h6"}>{activeDelivery.notes}</Typography>
        )}

        <Box height={heightNoteBox}>
          <Box
            height={activeDelivery.notes ? "calc(100% - 70px);" : "calc(100% - 40px);"}
            overflow="hidden"
            position="relative"
            padding={0}
            bgcolor="white"
            boxSizing="border-box"
            alignItems="center"
            textAlign={"center"}
          >
            {activeDelivery.delivery_notes &&
              activeDelivery.delivery_notes.length > 0 &&
              activeNoteImage ? (
              <NoteGallery
                activeNoteImage={activeNoteImage}
                fileName={activeDelivery.delivery_notes[indexActiveNote].file}
                rotation={rotation}
              />
            ) : (
              <>
                <br />
                <br />
                <br />
                <Typography variant={"h2"} color={"error"}>
                  ATTENZIONE <br /> BOLLA MANCANTE, <br />
                  PRIMA DI CARICARE <br />RECUPERARLA!
                </Typography>
              </>
            )}
            <Box
              position={"absolute"}
              top="0"
              display={"flex"}
              justifyContent="space-between"
              overflow="hidden"
              width={"100%"}
              padding={0}
              boxSizing="border-box"
              bgcolor={"white"}
              alignItems="center"
              textAlign={"center"}
            >
              <Box display={"flex"} alignItems="center" >
                <IconButton disabled={!canGoPrev} onClick={goPrev}>
                  <NavigatePrev />
                </IconButton>
                <Box mx={2}>
                  <Typography>
                    {indexActiveNote + 1}/{activeDelivery.delivery_notes.length}
                  </Typography>
                </Box>
                <IconButton disabled={!canGoNext} onClick={goNext}>
                  <NavigateNext />
                </IconButton>
              </Box>

              <Box display={"flex"} gridColumnGap="0px">
                <IconButton
                  disabled={!activeNoteImage}
                  style={{
                    background: theme.palette.primary.main,
                    color: "white",
                  }}
                  onClick={() => {
                    if (activeNoteImage) {
                      saveNoteToFile(
                        activeDelivery.delivery_notes[indexActiveNote].id
                      );
                    }
                  }}
                >
                  <GetAppIcon />
                </IconButton>
                <IconButton
                  style={{
                    background: theme.palette.primary.main,
                    color: "white",
                  }}
                  onClick={closeAside}
                >
                  <Close />
                </IconButton>
                <IconButton
                  style={{ background: theme.palette.primary.main, color: "white" }}
                  onClick={() => {
                    setRotation(rotation + 90);
                  }}
                >
                  <RotateRightIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </RecordContextProvider>
  ) : null;
};


interface LoadDeliveriesDatagridProps extends DatagridProps {
  setActiveDelivery: (charge: Record | undefined) => void;
  selectedDeliveries: Identifier[];
  setSelectedDeliveries: Dispatch<SetStateAction<Identifier[]>>;
  activeDelivery?: Record;
  setLoadComplete: any;
}
const useStyles = makeStyles((theme) => ({
  row: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
  headerCell: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.secondary.dark,
    borderBottom: "1px solid rgba(224, 224, 224, 1)!important",
    "& .select-all": {
      display: "none",
    },
  },
}));


export const LoadDeliveriesDatagrid = (props: LoadDeliveriesDatagridProps) => {
  const { selectedDeliveries, setSelectedDeliveries, activeDelivery, setActiveDelivery } =
    props;
  const { data: options } = useOptions("segments", "GET");
  const { data: optionsDelivery } = useOptions("deliveries", "GET");
  const refresh = useRefresh();
  const classes = useStyles();
  const mainRecord = useRecordContext();
  const theme = useTheme();
  const [firstOpen, setFirsOpen] = useState(true)
  const [disableChange, setDisableChange] = useState(false)
  const listContext = useList({
    data: mainRecord!.deliveries,
    ids: mainRecord!.deliveries.map((item: Record) => item.id),
    loading: false,
    loaded: true,
  });
  const [update, updateResponse] = useUpdate();
  const [updateDones, setUpdateDones] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (updateDones === listContext.ids.length && updateDones > 0) {
      window.location.reload()
    }
  }, [updateDones])

  useMemo(() => {
    if (mainRecord && mainRecord.route) {
      console.log(mainRecord)
      console.log(["L",].includes(mainRecord.route.status))
      setDisableChange(["L", "S"].includes(mainRecord.route.status))
    }
  }, [mainRecord])

  const onToggleItem = (id: Identifier) => {
    setSelectedDeliveries((old) => {
      if (old.includes(id)) { // SE DESELEZIONO
        update({
          resource: "segments",
          payload: {
            id: id,
            data: {
              loaded: false
            },
          }
        },
          {
            onSuccess: (e) => {
              listContext.data[id].loaded = false
              refresh()
            },
            onFailure: (e) => {
              console.log(e)
            }
          }
        );
        return old.filter((deliveryId) => deliveryId !== id);
      }
      update({
        resource: "segments",
        payload: {
          id: id,
          data: {
            loaded: true
          },
        }
      },
        {
          onSuccess: (e) => {
            listContext.data[id].loaded = true
            refresh()
          },
          onFailure: (e) => {
            console.log(e)
          }
        }
      );
      return [...old, id];
    });
  };
  const rowStyle = (record: Record) => {
    return {
      backgroundColor:
        record.id === activeDelivery?.id
          ? theme.palette.primary.light
          : record.color,
    };
  };

  useMemo(() => {
    if (firstOpen === true) {
      setFirsOpen(false)
      setActiveDelivery(listContext.data[listContext.ids[0]])
    }


  }, [listContext])

  function getSumFraction(listContext: any, attribute_to_sum: string) {
    var sum = 0;
    var total = 0;
    var rest = 0;
    for (const k in listContext.data) {
      total += listContext.data[k][attribute_to_sum];
      if (listContext.data[k].loaded === true) {
        sum += listContext.data[k][attribute_to_sum];
      };
    }
    sum = Math.round(sum * 1) / 1
    total = Math.round(total * 1) / 1
    rest = Math.round(total - sum) * 1 / 1

    return rest.toString() + " di " + total.toString();
  }

  return options && optionsDelivery ? (
    <Grid container>
      {
        loading && (
          <Grid item style={{
            position: "fixed", bottom: "0", right: "0",
            width: "100%", height: "100%",
            backgroundColor: "white", opacity: 0.5, zIndex: 10000000000,
            textAlign: "center", verticalAlign: "middle"
          }}>
            <AutorenewIcon fontSize="large" style={{
              position: "absolute",
              top: "50%", left: "50%",
            }} />
          </Grid>
        )
      }

      <Grid item style={{
        position: "fixed", bottom: "2px", right: "10px",
        width: "45%", minWidth: "600px", height: "50px",
        backgroundColor: "lightgray", zIndex: 1000000000,
        borderRadius: "10px"
      }}>
        <Grid container>
          <Grid xs={3} item>
            <Button style={{ marginTop: "5px" }} onClick={() => {
              setLoading(true)
              let update_dones = 0
              for (let id of listContext.ids) {
                update(
                  {
                    resource: "segments",
                    payload: {
                      id: id,
                      data: {
                        loaded: true
                      },
                    }
                  },
                  {
                    onSuccess: () => {
                      update_dones += 1
                      setUpdateDones(update_dones);
                    },
                    onFailure: (e: any) => {
                      update_dones += 1
                      setUpdateDones(update_dones);
                    },
                  }
                );
              }
            }}><Typography>Carica tutto</Typography>

            </Button>
          </Grid>
          <Grid xs={9} item>
            <Table style={{ padding: "2px", margin: "0px", width: "80%" }}>
              <TableRow style={{ padding: "2px" }}>
                <TableCell style={{ padding: "2px" }}>
                  <Typography align="center"><b>RESTANO DA <br />CARICARE</b>
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: "2px", borderLeft: "black solid 1px" }}>
                  <Typography align="center" title={"Quante ne mancano da caricare"}>Missioni: <br /><b>{listContext.ids.length - listContext.ids.filter((id) => listContext.data[id].loaded === true).length} di {listContext.ids.length}</b>
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: "2px", borderLeft: "black solid 1px" }}>
                  <Typography align="center" title={"Quante ne mancano da caricare"}>Colli: <br /><b>{getSumFraction(listContext, "packages_number")}</b>
                  </Typography>
                </TableCell>
                <TableCell style={{ padding: "2px", borderLeft: "black solid 1px" }}>
                  <Typography align="center" title={"Quante ne mancano da caricare"}>Peso (Kg): <br /><b>{getSumFraction(listContext, "weight")}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>

          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={6}>
        <ListContextProvider value={listContext}>
          <TableContainer>
            <EditableDatagrid
              hasBulkActions
              selectedIds={selectedDeliveries}
              classes={classes}
              stickyHeader
              onToggleItem={onToggleItem}
              rowStyle={rowStyle}
              isRowSelectable={() => false}
            >
              <LoadedEditableCheckboxField
                {...initField("loaded", options)}
                setLoadComplete={props.setLoadComplete}
                setActiveDelivery={setActiveDelivery}
                label={"Caricata"}
                disabled={() => disableChange}
              />
              <FunctionField
                tabIndex={-1}
                sortable={false}
                label="DDT"
                render={(tableRecord: Record | undefined) => {
                  if (tableRecord) {
                    return (
                      <Button
                        style={{ "padding": "0px" }}
                        label={tableRecord.document_number}
                        onClick={() => setActiveDelivery(tableRecord)}
                        tabIndex={-1}
                      ></Button>
                    );
                  }
                  return null;
                }}
              />
              <FunctionField
                tabIndex={-1}
                sortable={false}
                label="Stato"
                render={(tableRecord: Record | undefined) => {
                  if (tableRecord) {
                    return (
                      <RecordContextProvider value={{
                        id: tableRecord.delivery_id,
                        status: tableRecord.delivery_status,
                        delivery_status_display: tableRecord.delivery_status_display
                      }}>
                        <AddDeliveryStatusButtonWarehouse
                          refreshMain={true}
                          disabled={disableChange}
                        />
                      </RecordContextProvider>
                    );
                  }
                  return null;
                }}

              />


              <BooleanField sortable={false}{...initField("partial", options)} label="Parz" TrueIcon={CheckBox} FalseIcon={CheckBoxOutlineBlank} />,
              <NumberField sortable={false} {...initField("sequence", options)} label="Seq." />,
              <LittleSubjectField
                sortable={false} {...initField("client", options)} label="Cliente"
              />,
              <NumberField sortable={false} {...initField("weight", optionsDelivery)} />,
              <NumberField sortable={false} {...initField("packages_number", optionsDelivery)} />,

              {
                /*
 
                <TextField sortable={false} {...initField("document_number", optionsDelivery)} label="DDT" />,
                
                <TextField source="delivery_status_display" label="Stato" />,
                
                <FunctionField
                tabIndex={-1}
                sortable={false}
                label="Stato"
                render={(tableRecord: Record | undefined) => {
                  if (tableRecord) {
                    return (
                      <RecordContextProvider value={{
                        id: tableRecord.delivery_id,
                        status: tableRecord.delivery_status
                      }}>
                        <AddDeliveryStatusButton
                          refreshMain={true}
                        />
                      </RecordContextProvider>
                    );
                  }
                  return null;
                }}
              />
              <FunctionField
                tabIndex={-1}
                sortable={false}
                label="Bolle"
                render={(tableRecord: Record | undefined) => {
                  if (tableRecord) {
                    return (
                      <Button
                        label="Mostra bolle"
                        onClick={() => setActiveDelivery(tableRecord)}
                        tabIndex={-1}
                      ></Button>
                    );
                  }
                  return null;
                }}
              />
 
                */
              }

            </EditableDatagrid>
          </TableContainer>
        </ListContextProvider>
      </Grid>
      <Grid item xs={6}>
        <div style={{
          position: "fixed",
          top: "40px",
          width: "calc(50% - 22px)"
        }}>
          <Aside
            closeAside={() => setActiveDelivery(undefined)}
            activeDelivery={activeDelivery}
          />
        </div>


      </Grid>
    </Grid >

  ) : null;
};
