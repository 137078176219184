export const url = `/api`;
export const PERMISSIONS: any = {
  logs: {
    deliveries: "distribution.view_historicaldelivery",
    subjects: "registry.view_historicalsubject",
    cachets: "cash_on_delivery.view_historicalcachet",
    "delivery-items": "distribution.view_historicaldeliveryitem",
    "delivery-stocks": "distribution.view_historicalstock",
    charges: "accounting.view_historicalcharge",
  },
  deliveries: {
    edit: "distribution.change_delivery",
    view: "distribution.view_delivery",
  },
  // deliveries_to_schedule: {
  //   edit: "distribution.change_delivery",
  //   view: "distribution.view_delivery",
  // },
  "delivery-stocks": {
    edit: "distribution.change_stock",
    view: "distribution.view_stock",
  },
  subjects: {
    edit: "registry.change_subject",
    view: "registry.view_subject",
  },
  "imported-files": {
    edit: "file_import.change_importedfile",
    view: "file_import.view_importedfile",
  },
  upload: {
    edit: "file_import.change_importedfile",
    view: "file_import.view_importedfile",
  },
  employees: {
    edit: "employees.change_employee",
    view: "employees.view_employee",
  },
  badges: {
    edit: "employees.change_badge",
    view: "employees.view_badge",
  },
  presences: {
    edit: "employees.change_presence",
    view: "employees.view_presence",
  },
  presence_qr: {
    edit: "employees.change_presence",
    view: "employees.view_presence",
  },
  workpermits: {
    edit: "employees.change_workpermit",
    view: "employees.view_workpermit",
  },
  vehicles: {
    edit: "fleet_management.change_vehicle",
    view: "fleet_management.change_driver",
  },
  sites: {
    edit: "registry.change_site",
    view: "registry.change_site",
  },
  cachets: {
    edit: "cash_on_delivery.change_cachet",
    view: "cash_on_delivery.view_cachet",
  },
  routes: {
    edit: "route_management.change_route",
    view: "route_management.view_route",
  },
  "routes-monitor": {
    edit: "route_management.change_route",
    view: "route_management.view_route",
  },
  "delivery-groups": {
    edit: "route_management.change_route",
    view: "route_management.view_route",
  },
  notes: {
    edit: "distribution.change_note",
    view: "distribution.view_note",
  },
  categories: {
    edit: "distribution.change_category",
    view: "distribution.view_category",
  },
  items: {
    edit: "distribution.change_item",
    view: "distribution.view_item",
  },
  reason_status: {
    edit: "distribution.change_reasonstatus",
    view: "distribution.view_reasonstatus",
  },
  tariffs: {
    edit: "distribution.change_tariff",
    view: "distribution.view_tariff",
  },
  remittances: {
    edit: "distribution.change_remittance",
    view: "distribution.view_remittance",
  },
  charges: {
    edit: "accounting.change_charge",
    view: "accounting.view_charge",
  },
  invoices: {
    edit: "accounting.change_invoice",
    view: "accounting.view_invoice",
  },
  "invoice-payment-status": {
    edit: "accounting.change_invoicepaymentstatus",
    view: "accounting.view_invoicepaymentstatus",
  },
  ribas: {
    edit: "accounting.change_invoice",
    view: "accounting.view_invoice",
  },
  xml_invoices_imports: {
    edit: "accounting.change_invoice",
    view: "accounting.view_invoice",
  },
  customer_subjects: {
    edit: "customers.change_customersubject",
    view: "customers.view_customersubject",
  },
  customer_orders: {
    edit: "customers.change_customerorder",
    view: "customers.view_customerorder",
  },
  carrier_deliveries: {
    edit: "base.change_carrierdelivery",
    view: "base.view_carrierdelivery",
  },
  carrier_cashboxes: {
    view: "base.view_carriercashbox",
  },
  customer_import_files: {
    edit: "customers.change_customerimportfile",
    view: "customers.view_customerimportfile",
  },
  user_clients: {
    edit: "auth.change_user",
    view: "auth.view_user",
  },
  user_carriers: {
    edit: "auth.change_user",
    view: "auth.view_user",
  },
  chartaccounts: {
    edit: "accounting.change_chartaccounts",
    view: "accounting.view_chartaccounts",
  },
  chartaccountstags: {
    edit: "accounting.change_chartaccountstag",
    view: "accounting.view_chartaccountstag",
  },
  invoicechartaccounts: {
    edit: "accounting.change_invoicechartaccounts",
    view: "accounting.view_invoicechartaccounts",
  },
  delivery_mergers: {
    edit: "distribution.change_delivery",
    view: "distribution.view_delivery",
  },
  route_summaries: {
    edit: "route_management.change_route",
    view: "route_management.view_route",
  },
};

export const CREATE_ROUTE_API = `${url}/create-route`;

export const ASSIGN_MISSING_SUBJECT_API = `subjects-solver/assign_missing_subject`;